import axios from "axios";
let querystring = require('querystring');
import { Loading } from 'element-ui'

// let debugUrl = 'https://www.toiframe.com:2443/api'

if(process.env.NODE_ENV !== 'development') {
    axios.defaults.baseURL = 'https://yluxing.com/'
}
axios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (res) {
    let data = res.data
    // let code = data.code

    if (typeof data === 'string') {
        data = JSON.parse(data)
    }
    return data
}, function (error) {
    let response = error.response
    let data = response.data
    if (!response) {
        this.$message.error('无网络链接，请检查后重试')
    } else {
        this.$message.error(data && data.msg ? data.msg : '系统异常')
    }
    return Promise.reject(error);

})
/**
 * 发送 post 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export async function postWithJson(url, data = {}) {
    return axios({
        method: 'post',
        url,
        data,
        headers: {
            // 'token': sessionStorage.getItem('token')
        }
    })
}

/**
 * post form参数请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
 export function form(url, data = {}) {
    return axios.post(url,
        querystring.stringify(data), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
}

/**
 * 发送 delete 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
 export function del(url, data = {}) {
    return axios.delete(url, {
        params: data
    })
}

/**
 * 发送 get 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
 export function get(url, data = {}) {
    return axios.get(url, {
        params: data
    })
}

/**
 * 发送 update 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function put(url, data = {}) {
    return axios.put(url, data)
}

// 文件上传
export function afterRead(file) {
    const formData = new FormData(); // 声明一个FormData对象
    formData.append("file", file.file);
    return axios
        .post(
            "/wxapi/upload",
            formData, {
                headers: {
                    "content-type": "multer/form-data",
                }
            }
        )
        .then(res => {
            return res;
        });
}
/**
 * 上传
 * @param url
 * @param data
 */
 export function upload(url, file, data) {
    const formData = new FormData()
  
    // 附加数据
    if (data != null) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
    }
  
    formData.append('file', file)
  
    return new Promise((resolve, reject) => {
      // 打开
      const loading = Loading.service({
        text: '正在上传数据...',
        background: 'rgba(0, 0, 0, 0.7)'
      })
  
      axios.request({
        url: url,
        method: 'post',
        data: formData,
        timeout: 1200000
      }).then(response => {
        console.log(response)
        loading.close()
        resolve(response)
      }).catch(err => {
        loading.close()
        reject(err)
      })
    })
  }